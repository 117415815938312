<template>
  <!-- Filter Button -->
  <div class="card-style mb-0 row shadow-0">
    <a
      href="#"
      @click="openFilter('article-4')"
      class="
        close-menu
        btn btn-l
        col
        rounded-sm
        font-600
        btn-margins
        gradient-highlight
      "
      >Filter</a
    >
    <a
      @click="resetFilter"
      class="
        close-menu
        btn btn-l
        col
        rounded-sm
        font-600
        btn-margins
        bg-theme
        color-theme
      "
      >Reset</a
    >
  </div>
  <!-- End Filter Button -->

  <!-- Filter List -->
  <!-- End Filter List -->
</template>

<script>
import { sliderImage } from "@/components/menu/index.js";
export default {
  name: "FilterButton",
  emits: ["openFilter", "resetFilter"],
  props: {
    dataFilterList: Object,
  },
  data() {
    return {
      master_data: "",
      filterList: [],
    };
  },
  watch: {
    dataFilterList() {
      var data = this.dataFilterList;
      for (const key in data) {
        data[key].forEach((item) => {
          this.filterList.push(item.label);
        });
      }
      this.sliderImage();
    },
  },
  methods: {
    sliderImage,
    openFilter(data) {
      this.$emit("openFilter", data);
    },
    resetFilter() {
      this.$emit("resetFilter");
    },
  },
};
</script>
