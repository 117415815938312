<template>
  <div
    id="article-4"
    class="menu menu-box-modal rounded-0"
    data-menu-width="cover"
  >
    <div class="card mt-5 card-style">
      <div class="content">
        <h4>Filter</h4>
        <!-- Show More -->
        <div v-if="showMore">
          <div class="search-box search-dark rounded-m mb-3 mt-3">
            <i class="fa fa-search"></i>
            <input
              type="text"
              class="border-0"
              placeholder="Cari"
              v-model="dataSearch"
              @keyup="search"
            />
          </div>

          <div class="pt-1 pb-2 ps-3 pe-3">
            <div
              v-for="province in datas"
              :key="province.id"
              class="form-check interest-check font-12"
            >
              <input
                class="form-check-input"
                type="checkbox"
                :value="province.id"
                :id="province.name"
                :label="province.name"
                field="province"
                @click="addList"
                :checked="1 ? dataModels.includes(province.name) : 0"
              />
              <label
                class="form-check-label p-1 shadow-s rounded-s"
                :for="province.name"
                >{{ province.name }}</label
              >
            </div>
          </div>

          <a
            href="#"
            @click="showMore = false"
            class="
              close-menu
              btn btn-l
              rounded-sm
              font-600
              btn-full btn-margins
              gradient-highlight
            "
            >Simpan</a
          >
        </div>
        <!-- Show More -->

        <div v-if="!showMore" class="accordion" id="accordion-1">
          <!-- Location -->
          <div class="mb-0">
            <button class="btn accordion-btn no-effect color-theme">
              <i class="fas fa-map color-highlight me-2"></i>
              Lokasi
              <span
                class="font-10 accordion-icon color-blue-dark"
                @click="more('province')"
              >
                Lebih banyak</span
              >
            </button>
            <div style="">
              <div class="pt-1 pb-2 ps-3 pe-3">
                <div
                  v-for="province in provinces.slice(0, 6)"
                  :key="province.id"
                  class="form-check interest-check font-12"
                >
                  <input
                    class="form-check-input"
                    type="checkbox"
                    :value="province.id"
                    :id="province.name"
                    :label="province.name"
                    field="province"
                    :checked="1 ? dataModels.includes(province.name) : 0"
                    @click="addList"
                  />
                  <label
                    class="form-check-label p-1 shadow-s rounded-s"
                    :for="province.name"
                    >{{ province.name }}</label
                  >
                </div>
              </div>
            </div>
          </div>
          <!--End Location -->

          <!-- Work Time -->
          <div class="mb-0">
            <button
              class="btn accordion-btn no-effect color-theme collapsed"
              data-bs-toggle="collapse"
              data-bs-target="#collapse2"
              aria-expanded="false"
            >
              <i class="fas fa-clock color-highlight me-2"></i>
              Waktu Kerja
              <i class="fa fa-plus font-10 accordion-icon"></i>
            </button>
            <div
              id="collapse2"
              class="collapse"
              data-parent="#accordion-1"
              style=""
            >
              <div class="pt-1 pb-2 ps-3 pe-3">
                <div
                  v-for="worktime in options.worktime"
                  :key="worktime.value"
                  class="form-check interest-check font-12"
                >
                  <input
                    class="form-check-input"
                    type="checkbox"
                    :id="worktime.value"
                    :value="worktime.value"
                    :label="worktime.label"
                    field="worktime"
                    @click="addList"
                    :checked="1 ? dataModels.includes(worktime.label) : 0"
                  />
                  <label
                    class="form-check-label p-1 shadow-s rounded-s"
                    :for="worktime.value"
                    >{{ worktime.label }}</label
                  >
                </div>
              </div>
            </div>
          </div>
          <!--End Work Time -->

          <!--Remote -->
          <div class="mb-0">
            <button
              class="btn accordion-btn no-effect color-theme collapsed"
              data-bs-toggle="collapse"
              data-bs-target="#collapse3"
              aria-expanded="false"
            >
              <i class="fas fa-clock color-highlight me-2"></i>
              Remote
              <i class="fa fa-plus font-10 accordion-icon"></i>
            </button>
            <div
              id="collapse3"
              class="collapse"
              data-parent="#accordion-1"
              style=""
            >
              <div class="pt-1 pb-2 ps-3 pe-3">
                <div class="form-check interest-check font-12">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="remote"
                    value="1"
                    label="remote"
                    field="remote"
                    @click="addList"
                    :checked="1 ? dataModels.includes() : 0"
                  />
                  <label
                    class="form-check-label p-1 shadow-s rounded-s"
                    for="remote"
                    >Remote</label
                  >
                </div>
                <div class="form-check interest-check font-12">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="not-remote"
                    value="0"
                    label="not-remote"
                    field="remote"
                    @click="addList"
                    :checked="1 ? dataModels.includes() : 0"
                  />
                  <label
                    class="form-check-label p-1 shadow-s rounded-s"
                    for="not-remote"
                    >Tidak Remote</label
                  >
                </div>
              </div>
            </div>
          </div>
          <!--End Remote -->

          <!--Salary -->
          <div class="mb-0">
            <button
              class="btn accordion-btn no-effect color-theme collapsed"
              data-bs-toggle="collapse"
              data-bs-target="#collapse4"
              aria-expanded="false"
            >
              <i class="fas fa-clock color-highlight me-2"></i>
              Gaji
              <i class="fa fa-plus font-10 accordion-icon"></i>
            </button>
            <div
              id="collapse4"
              class="collapse"
              data-parent="#accordion-1"
              style=""
            >
              <div class="row ms-3 me-3">
                <div
                  class="input-style no-borders no-icon validate-field col-6"
                  :class="{ 'input-style-active': salary_start }"
                >
                  <input
                    type="name"
                    class="form-control validate-name"
                    id="form1e"
                    v-model="salary_start"
                    placeholder="minimal 10000"
                    required
                  />
                  <i class="fa fa-times disabled invalid color-red-dark"></i>
                  <i class="fa fa-check disabled valid color-green-dark"></i>
                </div>

                <div
                  class="input-style no-borders no-icon validate-field col-6"
                  :class="{ 'input-style-active': salary_end }"
                >
                  <input
                    type="name"
                    class="form-control validate-name"
                    id="form1f"
                    v-model="salary_end"
                    placeholder="misal 50000"
                    required
                  />
                  <i class="fa fa-times disabled invalid color-red-dark"></i>
                  <i class="fa fa-check disabled valid color-green-dark"></i>
                </div>
              </div>
            </div>
          </div>
          <!--End Salary -->
          <!-- Category -->
          <div v-if="categories" class="mb-0">
            <button
              class="btn accordion-btn no-effect color-theme collapsed"
              data-bs-toggle="collapse"
              data-bs-target="#collapse5"
              aria-expanded="false"
            >
              <i class="fas fa-th-large color-highlight me-2"></i>
              Category
              <i class="fa fa-plus font-10 accordion-icon"></i>
            </button>
            <div
              id="collapse5"
              class="collapse"
              data-parent="#accordion-1"
              style=""
            >
              <div class="pt-1 pb-2 ps-3 pe-3">
                <div
                  v-for="category in categories"
                  :key="category.id"
                  class="form-check interest-check font-12"
                >
                  <input
                    class="form-check-input"
                    type="checkbox"
                    :value="category.id"
                    :id="category.name"
                    field="category"
                    :label="category.name"
                    @click="addList"
                  />
                  <label
                    class="form-check-label p-1 shadow-s rounded-s"
                    :for="category.name"
                    >{{ category.name }}</label
                  >
                </div>
              </div>
            </div>
          </div>
          <!--End Category -->
        </div>
      </div>
    </div>
    <div class="text-center row me-3 ms-3">
      <a
        v-if="!showMore"
        href="#"
        @click="filter"
        class="
          close-menu
          btn btn-l
          col
          rounded-sm
          font-600
          btn-margins
          gradient-highlight
        "
        >Filter</a
      >
    </div>
  </div>
  <!-- Show More -->
</template>
<script>
import axios from "axios";
import { menuOpen } from "@/components/menu/index.js";
export default {
  name: "FilterList",
  props: {
    openFilter: Boolean,
    categories: Object,
    resetFilter: Boolean,
  },
  emits: ["filterList", "close"],
  data() {
    return {
      filterList: {},
      dataModels: [],
      options: {
        worktime: [
          { value: "FT", label: "Full Time" },
          { value: "PT", label: "Part Time" },
          { value: "FL", label: "Freelance" },
          { value: "IT", label: "Internship" },
        ],
      },
      masterData: this.$store.state.master_data,
      isLoading: true,
      provinces: [],
      showMore: false,
      salary_start: "",
      salary_end: "",
      errors: {},

      /*search*/
      dataSearch: "",
      datas: "",
    };
  },
  mounted() {
    this.getMasterData();
  },
  watch: {
    resetFilter() {
      if (this.resetFilter) {
        this.filterList = {};
        this.dataModels = [];
        this.$emit("filterList", this.filterList);
      }
    },
    dataSearch() {
      this.datas = this.provinces.filter((item) => {
        if (item.name.toLowerCase().includes(this.dataSearch)) {
          return item;
        }
      });
      if (this.dataSearch === "") {
        this.datas = this.provinces;
      }
    },
  },
  methods: {
    menuOpen,
    filter() {
      this.addSalary();
      this.$emit("filterList", this.filterList);
      this.$emit("close");
    },
    closeFilter() {
      this.$emit("close");
    },

    more(item) {
      this.showMore = true;
      if (item === "province") {
        this.datas = this.provinces;
      }
      /*this.closeFilter();*/
      /*setTimeout(() => {*/
      /*this.menuOpen("article-5");*/
      /*}, 300);*/
    },
    /*add or remove item filter*/
    addSalary() {
      if (this.salary_start) {
        var salaryStart = {
          value: this.salary_start,
          label: "salary_start",
        };
        !this.filterList["salary_start"]
          ? (this.filterList["salary_start"] = [])
          : "";
        if (!this.filterList["salary_start"].length) {
          this.filterList["salary_start"].push(salaryStart);
        }
      }

      if (this.salary_end) {
        var salaryEnd = {
          value: this.salary_end,
          label: "salary_end",
        };
        !this.filterList["salary_end"]
          ? (this.filterList["salary_end"] = [])
          : "";
        if (!this.filterList["salary_end"].length) {
          this.filterList["salary_end"].push(salaryEnd);
        }
      }
      if (!this.salary_start) {
        delete this.filterList["salary_start"];
      }
      if (!this.salary_end) {
        delete this.filterList["salary_end"];
      }
    },
    addList(event) {
      /* filter key exp, province, worktime  / */
      var field = event.target.attributes.getNamedItem("field").value;
      /* filter id / */
      var value = event.target.value;
      /* filter label / */
      var label = event.target.attributes.getNamedItem("label").value;

      if (event.target.checked) {
        var data = {
          value: value,
          label: label,
        };
        !this.filterList[field] ? (this.filterList[field] = []) : "";
        this.filterList[field].push(data);
        this.dataModels.push(label);
      }

      /*remove  from filter*/
      if (!event.target.checked) {
        this.filterList[field] = this.filterList[field].filter((item) => {
          return parseInt(value) !== parseInt(item.value);
        });
        /*remove key if null*/
        if (this.filterList[field].length === 0) {
          delete this.filterList[field];
        }
        this.dataModels.pop(label);
      }
    },
    async getMasterData() {
      await axios
        .get("/api/v1/employer/master/")
        .then((response) => {
          this.masterData = response.data;
          this.provinces = response.data.province.map((item) => {
            return {
              id: item.id,
              name: item.name,
            };
          });
          this.isLoading = false;
        })
        .catch((error) => {
          if (error.response) {
            console.log(
              "response data : " + JSON.stringify(error.response.data)
            );
          } else if (error.message) {
            console.log("error :" + JSON.stringify(error));
          }
        });
    },
  },
};
</script>
